<template>
    <div class="cs-numerical-dialogue-block cs-block" :class="`${styleClass}${hasBg}`">
        <div class="cs-block-base">
            <div class="container">
                <div class="header-content">

                    <h2 class="cs-title">
                        <ColorSplit :data="data.title" />
                    </h2>
                    <h3 class="cs-sub-title" v-if="data.subTitle">{{data.subTitle}}</h3>
                    <span class="cs-text" v-html="data.text"></span>

                </div>
                <div class="row">
                    <div class="col-lg-6" v-for="line, key in data.textList" :key="key">
                        <CSButton :data="line.url">
                            <div class="image">
                                <img :src="line.image" :alt="line.title">
                            </div>
                            <div class="line-item-content">
                                <h4>{{ line.title }}</h4>
                                <span v-html="line.text"/>
                                <p class="number">{{ line.number }}</p>
                            </div>
                        </CSButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {computed} from '../libs/common-fn';

export default {
    name: 'NumericalDialogue',
    components: {
        ColorSplit: () => import("../components/CSUtilsColorSplit.vue"),
        CSButton: () => import("../components/CSButton.vue"),
    },
    props: {
        data: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {}
    },
    computed: {
        ...computed('NumericalDialogue'),
        hasBg() {
            if (this.data.style == 'color') return ' has-background';

            return '';
        }
    }
}
</script>
<style lang="scss" scoped>
    @import '../styles/main.scss';
    .cs-block.cs-numerical-dialogue-block {
        &.cs-style- {
            &color {
                @include cs-block-color;
                .cs-block-base {
                    .cs-title, .cs-text{
                        color:white;
                    }

                    .cs-text {
                        ::v-deep {
                            * {
                                color: $text_color_over_theme_color !important;
                            }
                        }
                    }
                    .cs-sub-title {
                        color: $sub_title_color_light;
                    }

                }
            }

            &light {
                @include cs-block-light;
            }

            &dark {
                @include cs-block-dark;
            }
        }
        .header-content {
            text-align: center;
            margin-bottom: $header_margin;
            &:last-child {
                margin-bottom: 0;
            }
            .cs-text {
                ::v-deep {
                    *:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        ::v-deep .cs-button{
            text-decoration: none;
            display: flex;
            gap: 20px;
            padding: 20px;
            background-color: #E4EEF7;
            border-radius: 20px;
            margin-bottom: 20px;
            height: calc(100% - 20px);
            
            @media (max-width: 540px) {
                flex-direction: column;
            }

            .image {
                width: 35%;
                @media (max-width: 540px) {
                    width: 100%;
                }
                img {
                    object-fit: contain;
                    object-position: center;
                    width: 100%;
                    height: 100%;
                }
            }
            .line-item-content {
                width: 65%;
                @media (max-width: 540px) {
                    width: 100%;
                }
                h4{
                    @include title;
                    font-size: 18px;
                    margin-bottom: 15px;
                    @media (max-width:991px) {
                        font-size: 18px;
                    }
                }

                .number {
                    font-weight: $theme_bold_text;
                    font-size: 36px;
                    color: $theme_color;
                    margin-top: 15px;
                }

                span {
                    @include text;
                    ::v-deep {
                        *:last-child {
                            margin-bottom: 0;
                        }

                        p,
                        span {
                            @include text;
                        }
                    }
                }
            }
        }
        .col-lg-6:nth-child(4n+1)  {
            ::v-deep .cs-button {
                background-color: #F2E4F7;
                .number {
                    color:#BA68C8;
                }
            }
        }
        .col-lg-6:nth-child(4n+2) {
            ::v-deep .cs-button {
                background-color: #E4EEF7;
                .number {
                    color:#14AAF5;
                }
            }
        }
        .col-lg-6:nth-child(4n+3) {
            ::v-deep .cs-button {
                background-color: #E4F7E9;
                .number {
                    color:#1CBA8B;
                }
            }
        }
        .col-lg-6:nth-child(4n+4) {
            ::v-deep .cs-button {
                background-color: #F7F3E4;
                .number {
                    color:#FEB22E;
                }
            }
        }
    }
</style>